@import url(https://fonts.googleapis.com/css2?family=Candal&family=Lexend+Tera:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Candal", sans-serif;
  font-family: "Lexend Tera", sans-serif;
}

.snap-container-vertical {
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
}

.snap-container-horizontal {
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  height: 100vh;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: repeat(5, 100%);
}

.snap-container-horizontal::-webkit-scrollbar,
.snap-container-vertical::-webkit-scrollbar {
  display: none;
}

.snap-slide-vertical {
  height: calc(100vh - 0em);
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}

.snap-slide-horizontal {
  height: calc(100vh - 0em);
  width: calc(100vw - 0em);
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}

.carousel-slide {
  height: calc(100vh - 2em);
  /* width: calc(100vw - 0em); */
  width: 100%;
  text-align: center;
  position: relative;
}

.carousel-mainslide {
  /* height: calc(100vh - 56px); */
  height: 60vh;
  /* width: calc(100vw - 0em); */
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* h1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: black;
  width: 100%;
  left: 0;
  font-size: calc(1rem + 3vw);
} */

/* Vertical Dots */
.vertical-dots {
  right: 10px;
  list-style: none;
  display: block;
  position: absolute;
  top: 40%;
  margin-top: -10px;
  text-align: right;
}
.vertical-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.vertical-dots li button:hover,
.vertical-dots li button:focus {
  outline: none;
}
.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before {
  opacity: 1;
}
.vertical-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.slick-dots {
  bottom: 0 !important;
}

.banner_container{
  height: 100vh;
  width: 100%;
}

.mobile_banner_image{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.bgimg1,
.bgimg2,
.bgimg3,
.bgimg4,
.bgimg4,
.bgimg5,
.bgvideo1 {
  background-color: rgba(0, 0, 0, 0.6);
  color: aliceblue;
  /* transform-origin: 50% 50%;
transition: transform 7s linear; */
}

  .bgimgcomingsoon:after{
    content: "";
    background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/Coming_Soon_Plants_Compressed.png") no-repeat center center;
    background-size: cover;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
/* .bgimg11-desktop {
  background-image: url("./images/banner_new_diwali_sale.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */
.bgimg11-desktop:after {
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/black_friday_desktop_compressed.png") no-repeat center center;
  background-size: contain;  
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
/* .bgimg11-mobile {
  background: url("./images/diwali_portrait_banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */
.bgimg-new-year:after {
  content: "";
  /* background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/black_friday_mobile_compressed.png") no-repeat center center; */
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/new_year_sale_compressed.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
/* .bgimg6-mobile:after {
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/Buy6Jade_Mobile.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.bgimg12-mobile:after {
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/Buy12Jade_Mobile.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
} */
.bgimg1:after {
  content: "";
  /* background: url("./images/4_3_main_image.jpg") no-repeat center center; */
  /* background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/moneyplant/Round_White_Top_1.jpeg") no-repeat center center; */
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/spiderplant/Round_White_Full_1.jpeg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}

.bgimg2:after {
  content: "";
  /* background: url("./images/1080_curved_edges.jpg") no-repeat center center; */
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/jade/Round_White_Top_1.jpeg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}
.bgimg3:after {
  content: "";
  /* background: url("./images/money_back_3.jpg") no-repeat center center; */
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}
.bgimg4:after {
  content: "";
  /* background: url("./images/carousel-4-1.jpg") no-repeat center center; */
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}
.bgimg5:after {
  content: "";
  /* background: url("./images/carousel-3-1.jpg") no-repeat center center; */
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}

/* .bgvideo1,  */
.sliderVideo{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;

  /* content: "";
  background: url("./images/PetPlants.mp4") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5; */
}

.carousel_product_image{
  height: 150px;
  width: 150px;
}


/* Slider CSS Below */
.slick-prev,
.slick-next {
  z-index: 999;
}

.slick-next:before, .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #02b089;
}

.slick-prev {
  left: 25px;
}

.slick-next {
  right: 25px;
}

h1.slider_main_text.margin-top-27 {
  font-size: 45px;
}

span.slider_highlight {
  /* color: #F7B538; */
  color: #02b089;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-8-3 19:4:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

.feature_image {
  width: 100%;
  height: 100%;
}

button.buy_button {
  padding: 11px;
  border-radius: 5px;
  /* background-color: #003679; */
  /* background-color: #003679; */
  color: #fff;
  font-weight: 600;
  border: none;
}

.accordion-body {
  font-size: 12px;
}

.loader_container {
  position: absolute;
  /* position: relative; */
  height: 100%;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.7);
  color: #fff; */
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  z-index: 1;
}

.lds-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #02b089;
  -webkit-animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-loader div:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
          animation-delay: -0.24s;
}
.lds-loader div:nth-child(2) {
  left: 32px;
  -webkit-animation-delay: -0.12s;
          animation-delay: -0.12s;
}
.lds-loader div:nth-child(3) {
  left: 56px;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
.loader_text {
  font-size: 27px;
  color: #02b089;
}

img.header_logo {
  width: 45px;
  height: auto;
}

@-webkit-keyframes lds-loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

@keyframes lds-loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.navbarfiller{
  height: 70px;
  width: 100%;
}

.slogan{
  -webkit-transform: skewY(-5deg);
          transform: skewY(-5deg);
  /* transform: rotate(90deg)  */

}

.divCircle{
  border-radius: 50%;
}

.divSquare{
  border-radius: 5px;
}

.blog-image {
  max-width: 400px;
  height: auto;
  /* width: fit-content; */
}

sup.rupee {
  display: table-caption;
}

img.winner_img {
  width: -webkit-fill-available;
  max-height: 500px;
  max-width: 500px;
}

#winners{
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/bg_winners.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
  /* Utility Classes */

  /* :root {
    --default: 11px !important; 
  } */
  /* Default Padding */
 .no-padding {
     padding: 0 !important;
 }
 
 .no-padding-top {
     padding: 0 11px 11px 11px !important;
 }
 
 .no-padding-bottom {
     padding: 11px 11px 0 11px !important;
 }
 
 .padding {
     padding: 11px !important;
 }
 
 .padding-top {
     padding-top: 11px !important;
 }
 
 .padding-bottom {
     padding-bottom: 11px !important;
 }
 
 .padding-right {
     padding-right: 11px !important;
 }
 
 .padding-left {
     padding-left: 11px !important;
 }
 
  /* 5  Padding */
 .padding-5 {
     padding: 11px - 6px !important;
 }
 
 .padding-top-5 {
     padding-top: 11px - 6px !important;
 }
 
 .padding-bottom-5 {
     padding-bottom: 11px - 6px !important;
 }
 
 .padding-right-5 {
     padding-right: 11px - 6px !important;
 }
 
 .padding-left-5 {
     padding-left: 11px - 6px !important;
 }
 
  /* 10  Padding */
 .padding-10 {
     padding: 11px - 1px !important;
 }
 
 .padding-top-10 {
     padding-top: 11px - 1px !important;
 }
 
 .padding-bottom-10 {
     padding-bottom: 11px - 1px !important;
 }
 
 .padding-right-10 {
     padding-right: 11px - 1px !important;
 }
 
 .padding-left-10 {
     padding-left: 11px - 1px !important;
 }
 
  /* 18 Padding */
 .padding-18 {
     padding: 11px + 7px !important;
 }
 
 .padding-top-18 {
     padding-top: 11px + 7px !important;
 }
 
 .padding-bottom-18 {
     padding-bottom: 11px + 7px !important;
 }
 
 .padding-right-18 {
     padding-right: 11px + 7px !important;
 }
 
 .padding-left-18 {
     padding-left: 11px + 7px !important;
 }

 /* Padding Top 50 / 100 / 200 */
 .padding-top-50 {
    padding-top: 50px !important;
}

.padding-top-100 {
    padding-top: 100px !important;
}

.padding-top-200 {
    padding-top: 200px !important;
}

 /* Padding Bottom 50 / 100 / 200 */
.padding-bottom-50 {
    padding-bottom: 50px !important;
}

.padding-bottom-100 {
    padding-bottom: 100px !important;
}

.padding-bottom-200 {
    padding-bottom: 200px !important;
}
 
  /* Default Margin */
 .no-margin {
     margin: 0 !important;
 }
 
 .margin {
     margin: 11px !important;
 }
 
 .margin-top {
     margin-top: 11px !important;
 }
 
 .margin-bottom {
     margin-bottom: 11px !important;
 }
 
 .margin-right {
     margin-right: 11px !important;
 }
 
 .margin-left {
     margin-left: 11px !important;
 }
 
  /* 5 Margin */
  .margin-5 {
     margin: 11px - 6px !important;
 }
 
 .margin-top-5 {
     margin-top: 11px - 6px !important;
 }
 
 .margin-bottom-5 {
     margin-bottom: 11px - 6px !important;
 }
 
 .margin-right-5 {
     margin-right: 11px - 6px !important;
 }
 
 .margin-left-5 {
     margin-left: 11px - 6px !important;
 }
 
  /* 9 Margin */
  .margin-9 {
     margin: 11px - 2px !important;
 }
 
 .margin-top-9 {
     margin-top: 11px - 2px !important;
 }
 
 .margin-bottom-9 {
     margin-bottom: 11px - 2px !important;
 }
 
 .margin-right-9 {
     margin-right: 11px - 2px !important;
 }
 
 .margin-left-9 {
     margin-left: 11px - 2px !important;
 }
 
  /* 18 Margin */
 .margin-18 {
     margin: 11px + 7px !important;
 }
 
 .margin-top-18 {
     margin-top: calc(11px + 7px) !important;
 }
 
 .margin-bottom-18 {
     margin-bottom: calc(11px + 7px) !important;
 }
 
 .margin-right-18 {
     margin-right: 11px + 7px !important;
 }
 
 .margin-left-18 {
     margin-left: 11px + 7px !important;
 }
 
  /* 27 Margin */
 .margin-27 {
     margin: 11px + 16px !important;
 }
 
 .margin-top-27 {
    margin-top: 27px !important;
 }
 
 .margin-bottom-27 {
     margin-bottom: 11px + 16px !important;
 }
 
 .margin-right-27 {
     margin-right: 11px + 16px !important;
 }
 
 .margin-left-27 {
     margin-left: 11px + 16px !important;
 }
 
  /* Margin Top 50 / 100 / 200 */
 .margin-top-50 {
     margin-top: 50px !important;
 }
 
 .margin-top-100 {
     margin-top: 100px !important;
 }
 
 .margin-top-200 {
     margin-top: 200px !important;
 }
 
  /* Margin Bottom 50 / 100 / 200 */
 .margin-bottom-50 {
     margin-bottom: 50px !important;
 }
 
 .margin-bottom-100 {
     margin-bottom: 100px !important;
 }
 
 .margin-bottom-200 {
     margin-bottom: 200px !important;
 }
 
/* Margin Top - Bottom */
 .margin-top-bottom {
     margin-top: 11px !important;
     margin-bottom: 11px !important;
 }
 
 .margin-top-bottom-18 {
     margin-top: 11px + 7 !important;
     margin-bottom: 11px + 7 !important;
 }
 
 .margin-top-bottom-27 {
     margin-top: 11px + 16 !important;
     margin-bottom: 11px + 16 !important;
 }
 
 .margin-top-bottom-50 {
     margin-top: 50px !important;
     margin-bottom: 50px !important;
 }
 
 .margin-top-bottom-100 {
     margin-top: 100px !important;
     margin-bottom: 100px !important;
 }
 
  /* Margin Left - Right */
 .margin-left-right {
     margin-left: 11px !important;
     margin-right: 11px !important;
 }
 
  /* Font Sizes */
 .txtS9 {
     font-size: calc(11px - 2px) !important;
 }
 
 .txtS11 {
     font-size: 11px !important;
 }
 
 .txtS12{
     font-size: 12px !important;
 }
 
 .txtS14 {
     font-size: 14px !important;
 }
 
 .txtS18 {
     font-size: 18px !important;
 }
 
 .txtS20 {
     font-size: 20px !important;
 }
 
 .txtS27 {
     font-size: 27px !important;
 }
 
 .txtS36 {
     font-size: 36px !important;
 }
 
  /* Font Weight */
 .fw-500 {
     font-weight: 500 !important;
 }

 .fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}
 
  /* Heights */
 .h100P{
     height: 100% !important;
 }
 
 .h75P{
     height: 75% !important;
 }
 
 .h50P{
     height: 50% !important;
 }
 
 .h25P{
     height: 25% !important;
 }
 
 
 .h66P{
     height: 66% !important;
 }
 
 .h33P{
     height: 33% !important;
 }
 
 .h48{
     height: 48px !important;
 }

 .h-fit-content{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

/* Heights in VH */
.h100vh{
    height: 100vh !important;
}

.h75vh{
    height: 75vh !important;
}

.max-h60vh{
    max-height: 60vh !important;
}

.h60vh{
    height: 60vh !important;
}

.h50vh{
    height: 50vh !important;
}

.h25vh{
    height: 25vh !important;
}

.h66vh{
    height: 66vh !important;
}

.h33vh{
    height: 33vh !important;
}

.h10vh{
    height: 10vh !important;
}
 
  /* Background Colors */
  /* Swatch Colors */
 .bgSwatch1{
     background-color: #991717 !important;
 }
 
 .bgSwatch2{
     background-color: #b82223 !important;
 }
 
 .bgSwatch3{
     background-color: #c44140 !important;
 }
 
 .bgSwatch4{
     background-color: #f7d9d9 !important;
 }
 
 .bgSwatch5{
     background-color: #fbedec !important;
 }
  /* Swatch Colors */
 
 .bgGreen{
     background-color: #02B089 !important;
 }
 
 .bgRed{
     background-color: #EA556B !important; 
 }
 
 .bgLightGrey{
     background-color: #f7f7f7 !important;
 }
 
 .bgOrange{
     background-color: #F7B538 !important;
 }
 
 .bgNavyBlue{
     background-color: #49499B !important;
 }

 .bgPurple{
    background-color: #003679 !important;
}
 
 .bgBlack555{
     background-color: #555555 !important;
 }
 
 .bgOffWhite{
     background-color: #F8F9F9 !important;
 }

 .bgTailBlue{
    background-color: #34526a !important;
 }

 .bgPotWhite{
    background-color: whitesmoke !important;
 }

 .bgPotPink{
    background-color: #e54076 !important;
 }

 .bgPotLimeGreen{
    background-color: #C7EA46 !important;
 }

 .bgPotGreen{
    background-color: #6ddb56 !important;
 }

 .bgPotGrey{
    background-color: #a6c6ef !important;
 }

 .bgPotBlue{
    background-color: #0181fe !important;
 }

 .bgPotPurple{
    background-color: #4a11f6 !important;
 }
 
  /* Text Colors */
   /* Swatch Colors */
 .txtSwatch1{
     color: #991717 !important;
 }
 
 .txtSwatch2{
     color: #b82223 !important;
 }
 
 .txtSwatch3{
     color: #c44140 !important;
 }
 
 .txtSwatch4{
     color: #f7d9d9 !important;
 }
 
 .txtSwatch5{
     color: #fbedec !important;
 }
  /* Swatch Colors */
 
 .txtGreen{
     color: #02B089 !important;
 }
 
 /* .txtRed{
      color: #ED3E59 !important;
 } */
 
 .txtRed{
     color: #EA556B !important;
 }
 
 .txtBlack555 {
     color: #555555 !important;
 }
 
 .txtWhite{
     color: #ffffff !important;
 }
 
 .txtLightGrey{
     color: #f7f7f7 !important;
 }
 
 .txtOrange{
     color: #F7B538 !important;
 }
 
 .txtNavyBlue{
     color: #49499B !important;
 }
 
  /* Borders */
.borderWhite{
    border: 1px solid #ffffff !important;
}

  .borderLightgrey{
     border: 1px solid lightgrey !important;
 }
 
 .hr_grey {
     border-bottom: 1px solid lightgray !important;
 }

 .hr_lightgrey {
    border-bottom: 1px solid #d3d3d38a !important;
}
 
 .border_top_lightgray {
      /* border-top: 1px solid lightgray !important; */
     border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
 }

 .border_top_white {
    border-top: 1px solid #dee2e6 !important;
 }
 
 .border_bottom_lightgray {
      /* border-top: 1px solid lightgray !important; */
     border-bottom: 1px solid rgba(211, 211, 211, 0.5) !important;
 }
 
 .border_right_lightgray {
      /* border-top: 1px solid lightgray !important; */
     border-right: 1px solid rgba(211, 211, 211, 0.5) !important;
 }
 
 
 
  /* Border Radius */
 .br_2 {
     border-radius: 2px !important;
 }
 
 .br_4 {
     border-radius: 4px !important;
 }
 
 .br_6 {
     border-radius: 6px !important;
 }
 
 .br_8 {
     border-radius: 8px !important;
 }
 
 .br_10 {
     border-radius: 10px !important;
 }
 
 /* Floats */
 .pull-left{
     float: left !important;
 }
 
 .pull-right{
     float: right !important;
 }
 
  /* Width */
 .one-one-width{
     width: 1% !important;
 }
 
 .one-fifth-width{
     width: 20% !important;
 }
 
 .one-seventeenth-width{
     width: 17% !important;
 }
 
 .quarter-width{
     width: 25% !important;
 }
 
 .half-width {
     width: 50% !important;
 }
 
 .three-quarter-width {
     width: 75% !important;
 }
 
 .full-width {
     width: 100% !important;
 }
 
 .offset-quarter{
     left: 25% !important
 }
 
 .offset-half{
     left: 50% !important
 }
 
 .minimum_width_auto{
     min-width: auto !important
 }
 
  /* Display */
 .block {
     display: block !important;
 }
 
 .inline {
     display: inline !important;
 }
 
 .flex-column{
     display: flex;
     flex-direction: column;
 }
 
 .flex-center {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
     flex-direction: column;
 }
 
 .inline-flex {
     display: inline-flex !important;
 }
 
 .inline-block {
     display: inline-block !important;
 }
 
 .table-display{
     display: table !important
 }
 
 .table-cell {
     display: table-cell !important;
 }
 
 .absolute {
     position: absolute !important;
 }
 
 .fixed {
     position: fixed !important;
     z-index: 1 !important;
 }
 
 .sticky-top {
     position: -webkit-sticky !important;
     position: sticky !important;
     top: 0 !important;
 }
 .sticky-bottom {
     position: -webkit-sticky !important;
     position: sticky !important;
     bottom: 18px !important;
 }

 .z-index-9{
    z-index: 9 !important;
 }
 
 mat-toolbar.mat-toolbar.bgGreen.txtWhite.sticky-bottom-menu {
     position: fixed !important;
     bottom: 0 !important;
     z-index: 1;
     width: 100%;
 }
 
 .ellipse {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   }
 
 .right {
     right: 0 !important;
 }
 
 .justify-center {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
 }
 
 .justify-left {
     display: flex !important;
     justify-content: flex-start !important;
 }
 
 .justify-right {
     display: flex !important;
     justify-content: flex-end !important;
 }
 
  /* Underlines */
 .underline-green {
     border-bottom: 2px solid #02B089;
 }
 
 .underline-orange {
     border-bottom: 2px solid #F7B538;
 }
 
 .underline-lightgray {
     border-bottom: 1px solid lightgray;
 }
 
  /* Cursor */
 .cursor-pointer {
     cursor: pointer !important;
 }
 
 
  /* Text Transformation */
 .capitalise {
     text-transform: capitalize !important;
 }
 
 .uppercase {
     text-transform: uppercase !important;
 }
 
 /* a:hover, a:visited, a:link, a:active,  */
 .no-decoration
 {
     text-decoration: none !important;
 }

 .text-right{
    text-align: right !important;
 }

 .text-left{
    text-align: left !important;
 }
 
  /* Minimum Height */
 .min-height-50 {
     min-height: 50px !important;
 }

 .min-height-100 {
    min-height: 100px !important;
}

.min-height-200 {
    min-height: 200px !important;
}

.min-height-300 {
    min-height: 300px !important;
}

.min-height-400 {
    min-height: 400px !important;
}

/* Error Classes */
.inputError {
    border: 1px solid red;
    border-left: 5px solid red;
}
 
.user-select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
 
/* Square Sizes */
.square-9{
    height: 9px;
    width: 9px;
}

.square-15{
    height: 15px;
    width: 15px;
}

.square-18{
    height: 18px;
    width: 18px;
}

.square-25{
    height: 25px;
    width: 25px;
}

.square-36{
    height: 36px;
    width: 36px;
}

.square-50{
    height: 50px;
    width: 50px;
}

.square-100{
    height: 100px;
    width: 100px;
}

.square-200{
    height: 200px;
    width: 200px;
}

/* Utility Classes */
 
 
.featureCardContainer {
    /* Sizing */
    width: 100vw;
    height: 100vh;
    
    /* Flexbox stuff */
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* Text styles */
    text-align: center;
    
    /* Background styles */
    /* background-image: url(https://codetheweb.blog/assets/img/posts/full-image-hero/image.jpg); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    border-top: 1px solid #dee2e6;
}

.featureCardText{
    color: #f8f9fa !important;
    background-color: rgb(65 70 75 / 50%);
    padding: 18px 27px;
    border-radius: 5px;
}
