.featureCardContainer {
    /* Sizing */
    width: 100vw;
    height: 100vh;
    
    /* Flexbox stuff */
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* Text styles */
    text-align: center;
    
    /* Background styles */
    /* background-image: url(https://codetheweb.blog/assets/img/posts/full-image-hero/image.jpg); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    border-top: 1px solid #dee2e6;
}

.featureCardText{
    color: #f8f9fa !important;
    background-color: rgb(65 70 75 / 50%);
    padding: 18px 27px;
    border-radius: 5px;
}