  /* Utility Classes */

  /* :root {
    --default: 11px !important; 
  } */
  /* Default Padding */
 .no-padding {
     padding: 0 !important;
 }
 
 .no-padding-top {
     padding: 0 11px 11px 11px !important;
 }
 
 .no-padding-bottom {
     padding: 11px 11px 0 11px !important;
 }
 
 .padding {
     padding: 11px !important;
 }
 
 .padding-top {
     padding-top: 11px !important;
 }
 
 .padding-bottom {
     padding-bottom: 11px !important;
 }
 
 .padding-right {
     padding-right: 11px !important;
 }
 
 .padding-left {
     padding-left: 11px !important;
 }
 
  /* 5  Padding */
 .padding-5 {
     padding: 11px - 6px !important;
 }
 
 .padding-top-5 {
     padding-top: 11px - 6px !important;
 }
 
 .padding-bottom-5 {
     padding-bottom: 11px - 6px !important;
 }
 
 .padding-right-5 {
     padding-right: 11px - 6px !important;
 }
 
 .padding-left-5 {
     padding-left: 11px - 6px !important;
 }
 
  /* 10  Padding */
 .padding-10 {
     padding: 11px - 1px !important;
 }
 
 .padding-top-10 {
     padding-top: 11px - 1px !important;
 }
 
 .padding-bottom-10 {
     padding-bottom: 11px - 1px !important;
 }
 
 .padding-right-10 {
     padding-right: 11px - 1px !important;
 }
 
 .padding-left-10 {
     padding-left: 11px - 1px !important;
 }
 
  /* 18 Padding */
 .padding-18 {
     padding: 11px + 7px !important;
 }
 
 .padding-top-18 {
     padding-top: 11px + 7px !important;
 }
 
 .padding-bottom-18 {
     padding-bottom: 11px + 7px !important;
 }
 
 .padding-right-18 {
     padding-right: 11px + 7px !important;
 }
 
 .padding-left-18 {
     padding-left: 11px + 7px !important;
 }

 /* Padding Top 50 / 100 / 200 */
 .padding-top-50 {
    padding-top: 50px !important;
}

.padding-top-100 {
    padding-top: 100px !important;
}

.padding-top-200 {
    padding-top: 200px !important;
}

 /* Padding Bottom 50 / 100 / 200 */
.padding-bottom-50 {
    padding-bottom: 50px !important;
}

.padding-bottom-100 {
    padding-bottom: 100px !important;
}

.padding-bottom-200 {
    padding-bottom: 200px !important;
}
 
  /* Default Margin */
 .no-margin {
     margin: 0 !important;
 }
 
 .margin {
     margin: 11px !important;
 }
 
 .margin-top {
     margin-top: 11px !important;
 }
 
 .margin-bottom {
     margin-bottom: 11px !important;
 }
 
 .margin-right {
     margin-right: 11px !important;
 }
 
 .margin-left {
     margin-left: 11px !important;
 }
 
  /* 5 Margin */
  .margin-5 {
     margin: 11px - 6px !important;
 }
 
 .margin-top-5 {
     margin-top: 11px - 6px !important;
 }
 
 .margin-bottom-5 {
     margin-bottom: 11px - 6px !important;
 }
 
 .margin-right-5 {
     margin-right: 11px - 6px !important;
 }
 
 .margin-left-5 {
     margin-left: 11px - 6px !important;
 }
 
  /* 9 Margin */
  .margin-9 {
     margin: 11px - 2px !important;
 }
 
 .margin-top-9 {
     margin-top: 11px - 2px !important;
 }
 
 .margin-bottom-9 {
     margin-bottom: 11px - 2px !important;
 }
 
 .margin-right-9 {
     margin-right: 11px - 2px !important;
 }
 
 .margin-left-9 {
     margin-left: 11px - 2px !important;
 }
 
  /* 18 Margin */
 .margin-18 {
     margin: 11px + 7px !important;
 }
 
 .margin-top-18 {
     margin-top: calc(11px + 7px) !important;
 }
 
 .margin-bottom-18 {
     margin-bottom: calc(11px + 7px) !important;
 }
 
 .margin-right-18 {
     margin-right: 11px + 7px !important;
 }
 
 .margin-left-18 {
     margin-left: 11px + 7px !important;
 }
 
  /* 27 Margin */
 .margin-27 {
     margin: 11px + 16px !important;
 }
 
 .margin-top-27 {
    margin-top: 27px !important;
 }
 
 .margin-bottom-27 {
     margin-bottom: 11px + 16px !important;
 }
 
 .margin-right-27 {
     margin-right: 11px + 16px !important;
 }
 
 .margin-left-27 {
     margin-left: 11px + 16px !important;
 }
 
  /* Margin Top 50 / 100 / 200 */
 .margin-top-50 {
     margin-top: 50px !important;
 }
 
 .margin-top-100 {
     margin-top: 100px !important;
 }
 
 .margin-top-200 {
     margin-top: 200px !important;
 }
 
  /* Margin Bottom 50 / 100 / 200 */
 .margin-bottom-50 {
     margin-bottom: 50px !important;
 }
 
 .margin-bottom-100 {
     margin-bottom: 100px !important;
 }
 
 .margin-bottom-200 {
     margin-bottom: 200px !important;
 }
 
/* Margin Top - Bottom */
 .margin-top-bottom {
     margin-top: 11px !important;
     margin-bottom: 11px !important;
 }
 
 .margin-top-bottom-18 {
     margin-top: 11px + 7 !important;
     margin-bottom: 11px + 7 !important;
 }
 
 .margin-top-bottom-27 {
     margin-top: 11px + 16 !important;
     margin-bottom: 11px + 16 !important;
 }
 
 .margin-top-bottom-50 {
     margin-top: 50px !important;
     margin-bottom: 50px !important;
 }
 
 .margin-top-bottom-100 {
     margin-top: 100px !important;
     margin-bottom: 100px !important;
 }
 
  /* Margin Left - Right */
 .margin-left-right {
     margin-left: 11px !important;
     margin-right: 11px !important;
 }
 
  /* Font Sizes */
 .txtS9 {
     font-size: calc(11px - 2px) !important;
 }
 
 .txtS11 {
     font-size: 11px !important;
 }
 
 .txtS12{
     font-size: 12px !important;
 }
 
 .txtS14 {
     font-size: 14px !important;
 }
 
 .txtS18 {
     font-size: 18px !important;
 }
 
 .txtS20 {
     font-size: 20px !important;
 }
 
 .txtS27 {
     font-size: 27px !important;
 }
 
 .txtS36 {
     font-size: 36px !important;
 }
 
  /* Font Weight */
 .fw-500 {
     font-weight: 500 !important;
 }

 .fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}
 
  /* Heights */
 .h100P{
     height: 100% !important;
 }
 
 .h75P{
     height: 75% !important;
 }
 
 .h50P{
     height: 50% !important;
 }
 
 .h25P{
     height: 25% !important;
 }
 
 
 .h66P{
     height: 66% !important;
 }
 
 .h33P{
     height: 33% !important;
 }
 
 .h48{
     height: 48px !important;
 }

 .h-fit-content{
    height: fit-content !important;
}

/* Heights in VH */
.h100vh{
    height: 100vh !important;
}

.h75vh{
    height: 75vh !important;
}

.max-h60vh{
    max-height: 60vh !important;
}

.h60vh{
    height: 60vh !important;
}

.h50vh{
    height: 50vh !important;
}

.h25vh{
    height: 25vh !important;
}

.h66vh{
    height: 66vh !important;
}

.h33vh{
    height: 33vh !important;
}

.h10vh{
    height: 10vh !important;
}
 
  /* Background Colors */
  /* Swatch Colors */
 .bgSwatch1{
     background-color: #991717 !important;
 }
 
 .bgSwatch2{
     background-color: #b82223 !important;
 }
 
 .bgSwatch3{
     background-color: #c44140 !important;
 }
 
 .bgSwatch4{
     background-color: #f7d9d9 !important;
 }
 
 .bgSwatch5{
     background-color: #fbedec !important;
 }
  /* Swatch Colors */
 
 .bgGreen{
     background-color: #02B089 !important;
 }
 
 .bgRed{
     background-color: #EA556B !important; 
 }
 
 .bgLightGrey{
     background-color: #f7f7f7 !important;
 }
 
 .bgOrange{
     background-color: #F7B538 !important;
 }
 
 .bgNavyBlue{
     background-color: #49499B !important;
 }

 .bgPurple{
    background-color: #003679 !important;
}
 
 .bgBlack555{
     background-color: #555555 !important;
 }
 
 .bgOffWhite{
     background-color: #F8F9F9 !important;
 }

 .bgTailBlue{
    background-color: #34526a !important;
 }

 .bgPotWhite{
    background-color: whitesmoke !important;
 }

 .bgPotPink{
    background-color: #e54076 !important;
 }

 .bgPotLimeGreen{
    background-color: #C7EA46 !important;
 }

 .bgPotGreen{
    background-color: #6ddb56 !important;
 }

 .bgPotGrey{
    background-color: #a6c6ef !important;
 }

 .bgPotBlue{
    background-color: #0181fe !important;
 }

 .bgPotPurple{
    background-color: #4a11f6 !important;
 }
 
  /* Text Colors */
   /* Swatch Colors */
 .txtSwatch1{
     color: #991717 !important;
 }
 
 .txtSwatch2{
     color: #b82223 !important;
 }
 
 .txtSwatch3{
     color: #c44140 !important;
 }
 
 .txtSwatch4{
     color: #f7d9d9 !important;
 }
 
 .txtSwatch5{
     color: #fbedec !important;
 }
  /* Swatch Colors */
 
 .txtGreen{
     color: #02B089 !important;
 }
 
 /* .txtRed{
      color: #ED3E59 !important;
 } */
 
 .txtRed{
     color: #EA556B !important;
 }
 
 .txtBlack555 {
     color: #555555 !important;
 }
 
 .txtWhite{
     color: #ffffff !important;
 }
 
 .txtLightGrey{
     color: #f7f7f7 !important;
 }
 
 .txtOrange{
     color: #F7B538 !important;
 }
 
 .txtNavyBlue{
     color: #49499B !important;
 }
 
  /* Borders */
.borderWhite{
    border: 1px solid #ffffff !important;
}

  .borderLightgrey{
     border: 1px solid lightgrey !important;
 }
 
 .hr_grey {
     border-bottom: 1px solid lightgray !important;
 }

 .hr_lightgrey {
    border-bottom: 1px solid #d3d3d38a !important;
}
 
 .border_top_lightgray {
      /* border-top: 1px solid lightgray !important; */
     border-top: 1px solid rgba(211, 211, 211, 0.5) !important;
 }

 .border_top_white {
    border-top: 1px solid #dee2e6 !important;
 }
 
 .border_bottom_lightgray {
      /* border-top: 1px solid lightgray !important; */
     border-bottom: 1px solid rgba(211, 211, 211, 0.5) !important;
 }
 
 .border_right_lightgray {
      /* border-top: 1px solid lightgray !important; */
     border-right: 1px solid rgba(211, 211, 211, 0.5) !important;
 }
 
 
 
  /* Border Radius */
 .br_2 {
     border-radius: 2px !important;
 }
 
 .br_4 {
     border-radius: 4px !important;
 }
 
 .br_6 {
     border-radius: 6px !important;
 }
 
 .br_8 {
     border-radius: 8px !important;
 }
 
 .br_10 {
     border-radius: 10px !important;
 }
 
 /* Floats */
 .pull-left{
     float: left !important;
 }
 
 .pull-right{
     float: right !important;
 }
 
  /* Width */
 .one-one-width{
     width: 1% !important;
 }
 
 .one-fifth-width{
     width: 20% !important;
 }
 
 .one-seventeenth-width{
     width: 17% !important;
 }
 
 .quarter-width{
     width: 25% !important;
 }
 
 .half-width {
     width: 50% !important;
 }
 
 .three-quarter-width {
     width: 75% !important;
 }
 
 .full-width {
     width: 100% !important;
 }
 
 .offset-quarter{
     left: 25% !important
 }
 
 .offset-half{
     left: 50% !important
 }
 
 .minimum_width_auto{
     min-width: auto !important
 }
 
  /* Display */
 .block {
     display: block !important;
 }
 
 .inline {
     display: inline !important;
 }
 
 .flex-column{
     display: flex;
     flex-direction: column;
 }
 
 .flex-center {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
     flex-direction: column;
 }
 
 .inline-flex {
     display: inline-flex !important;
 }
 
 .inline-block {
     display: inline-block !important;
 }
 
 .table-display{
     display: table !important
 }
 
 .table-cell {
     display: table-cell !important;
 }
 
 .absolute {
     position: absolute !important;
 }
 
 .fixed {
     position: fixed !important;
     z-index: 1 !important;
 }
 
 .sticky-top {
     position: sticky !important;
     top: 0 !important;
 }
 .sticky-bottom {
     position: sticky !important;
     bottom: 18px !important;
 }

 .z-index-9{
    z-index: 9 !important;
 }
 
 mat-toolbar.mat-toolbar.bgGreen.txtWhite.sticky-bottom-menu {
     position: fixed !important;
     bottom: 0 !important;
     z-index: 1;
     width: 100%;
 }
 
 .ellipse {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   }
 
 .right {
     right: 0 !important;
 }
 
 .justify-center {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
 }
 
 .justify-left {
     display: flex !important;
     justify-content: flex-start !important;
 }
 
 .justify-right {
     display: flex !important;
     justify-content: flex-end !important;
 }
 
  /* Underlines */
 .underline-green {
     border-bottom: 2px solid #02B089;
 }
 
 .underline-orange {
     border-bottom: 2px solid #F7B538;
 }
 
 .underline-lightgray {
     border-bottom: 1px solid lightgray;
 }
 
  /* Cursor */
 .cursor-pointer {
     cursor: pointer !important;
 }
 
 
  /* Text Transformation */
 .capitalise {
     text-transform: capitalize !important;
 }
 
 .uppercase {
     text-transform: uppercase !important;
 }
 
 /* a:hover, a:visited, a:link, a:active,  */
 .no-decoration
 {
     text-decoration: none !important;
 }

 .text-right{
    text-align: right !important;
 }

 .text-left{
    text-align: left !important;
 }
 
  /* Minimum Height */
 .min-height-50 {
     min-height: 50px !important;
 }

 .min-height-100 {
    min-height: 100px !important;
}

.min-height-200 {
    min-height: 200px !important;
}

.min-height-300 {
    min-height: 300px !important;
}

.min-height-400 {
    min-height: 400px !important;
}

/* Error Classes */
.inputError {
    border: 1px solid red;
    border-left: 5px solid red;
}
 
.user-select-none {
    user-select: none;
}
 
/* Square Sizes */
.square-9{
    height: 9px;
    width: 9px;
}

.square-15{
    height: 15px;
    width: 15px;
}

.square-18{
    height: 18px;
    width: 18px;
}

.square-25{
    height: 25px;
    width: 25px;
}

.square-36{
    height: 36px;
    width: 36px;
}

.square-50{
    height: 50px;
    width: 50px;
}

.square-100{
    height: 100px;
    width: 100px;
}

.square-200{
    height: 200px;
    width: 200px;
}

/* Utility Classes */
 
 