@import url("https://fonts.googleapis.com/css2?family=Candal&family=Lexend+Tera:wght@300;400;500;700;900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  font-family: "Candal", sans-serif;
  font-family: "Lexend Tera", sans-serif;
}

.snap-container-vertical {
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
}

.snap-container-horizontal {
  scroll-snap-type: x mandatory;
  height: 100vh;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: repeat(5, 100%);
}

.snap-container-horizontal::-webkit-scrollbar,
.snap-container-vertical::-webkit-scrollbar {
  display: none;
}

.snap-slide-vertical {
  height: calc(100vh - 0em);
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}

.snap-slide-horizontal {
  height: calc(100vh - 0em);
  width: calc(100vw - 0em);
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}

.carousel-slide {
  height: calc(100vh - 2em);
  /* width: calc(100vw - 0em); */
  width: 100%;
  text-align: center;
  position: relative;
}

.carousel-mainslide {
  /* height: calc(100vh - 56px); */
  height: 60vh;
  /* width: calc(100vw - 0em); */
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* h1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: black;
  width: 100%;
  left: 0;
  font-size: calc(1rem + 3vw);
} */

/* Vertical Dots */
.vertical-dots {
  right: 10px;
  list-style: none;
  display: block;
  position: absolute;
  top: 40%;
  margin-top: -10px;
  text-align: right;
}
.vertical-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.vertical-dots li button:hover,
.vertical-dots li button:focus {
  outline: none;
}
.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before {
  opacity: 1;
}
.vertical-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.slick-dots {
  bottom: 0 !important;
}

.banner_container{
  height: 100vh;
  width: 100%;
}

.mobile_banner_image{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.bgimg1,
.bgimg2,
.bgimg3,
.bgimg4,
.bgimg4,
.bgimg5,
.bgvideo1 {
  background-color: rgba(0, 0, 0, 0.6);
  color: aliceblue;
  /* transform-origin: 50% 50%;
transition: transform 7s linear; */
}

  .bgimgcomingsoon:after{
    content: "";
    background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/Coming_Soon_Plants_Compressed.png") no-repeat center center;
    background-size: cover;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
/* .bgimg11-desktop {
  background-image: url("./images/banner_new_diwali_sale.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */
.bgimg11-desktop:after {
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/black_friday_desktop_compressed.png") no-repeat center center;
  background-size: contain;  
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
/* .bgimg11-mobile {
  background: url("./images/diwali_portrait_banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */
.bgimg-new-year:after {
  content: "";
  /* background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/black_friday_mobile_compressed.png") no-repeat center center; */
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/new_year_sale_compressed.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
/* .bgimg6-mobile:after {
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/Buy6Jade_Mobile.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.bgimg12-mobile:after {
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/Buy12Jade_Mobile.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
} */
.bgimg1:after {
  content: "";
  /* background: url("./images/4_3_main_image.jpg") no-repeat center center; */
  /* background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/moneyplant/Round_White_Top_1.jpeg") no-repeat center center; */
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/spiderplant/Round_White_Full_1.jpeg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}

.bgimg2:after {
  content: "";
  /* background: url("./images/1080_curved_edges.jpg") no-repeat center center; */
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/jade/Round_White_Top_1.jpeg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}
.bgimg3:after {
  content: "";
  /* background: url("./images/money_back_3.jpg") no-repeat center center; */
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}
.bgimg4:after {
  content: "";
  /* background: url("./images/carousel-4-1.jpg") no-repeat center center; */
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}
.bgimg5:after {
  content: "";
  /* background: url("./images/carousel-3-1.jpg") no-repeat center center; */
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  -webkit-animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
  animation: kenburns-top 7s ease-in-out infinite alternate-reverse both;
}

/* .bgvideo1,  */
.sliderVideo{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;

  /* content: "";
  background: url("./images/PetPlants.mp4") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5; */
}

.carousel_product_image{
  height: 150px;
  width: 150px;
}


/* Slider CSS Below */
.slick-prev,
.slick-next {
  z-index: 999;
}

.slick-next:before, .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #02b089;
}

.slick-prev {
  left: 25px;
}

.slick-next {
  right: 25px;
}

h1.slider_main_text.margin-top-27 {
  font-size: 45px;
}

span.slider_highlight {
  /* color: #F7B538; */
  color: #02b089;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-8-3 19:4:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

.feature_image {
  width: 100%;
  height: 100%;
}

button.buy_button {
  padding: 11px;
  border-radius: 5px;
  /* background-color: #003679; */
  /* background-color: #003679; */
  color: #fff;
  font-weight: 600;
  border: none;
}

.accordion-body {
  font-size: 12px;
}

.loader_container {
  position: absolute;
  /* position: relative; */
  height: 100%;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.7);
  color: #fff; */
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  z-index: 1;
}

.lds-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #02b089;
  animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
.loader_text {
  font-size: 27px;
  color: #02b089;
}

img.header_logo {
  width: 45px;
  height: auto;
}

@keyframes lds-loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.navbarfiller{
  height: 70px;
  width: 100%;
}

.slogan{
  transform: skewY(-5deg);
  /* transform: rotate(90deg)  */

}

.divCircle{
  border-radius: 50%;
}

.divSquare{
  border-radius: 5px;
}

.blog-image {
  max-width: 400px;
  height: auto;
  /* width: fit-content; */
}

sup.rupee {
  display: table-caption;
}

img.winner_img {
  width: -webkit-fill-available;
  max-height: 500px;
  max-width: 500px;
}

#winners{
  content: "";
  background: url("https://s3.ap-south-1.amazonaws.com/images.petplants.in/banners/bg_winners.png") no-repeat center center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}